export default {
  state: {
    zits: '',
  },
  mutations: {
    setZits(state, zits) {
      state.zits = zits;
    },
  },
};
